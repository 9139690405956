import {
  quickBuyModalDataTrackingCallbacks,
  useAddToBasket,
  useLogger,
} from '@thg-commerce/enterprise-core'
import { InteractionLocation } from '@thg-commerce/enterprise-core/src/Basket'
import { FulfilmentMethod } from '@thg-commerce/enterprise-network/src/generated/graphql'
import { Beacon } from '@thg-commerce/enterprise-network/src/transformers/sponsoredAds/products'

export const useQuickBuyClickHandler = (
  sku: string,
  fromRecommendations: boolean = false,
  onBasketChangeBeacon?: Beacon,
  trackingUrl?: string,
  initialSelectedOptions?: { [optionKey: string]: string },
  setSelectedOptionsCallback?: (selectedOptions) => void,
  setSelectedVariantCallback?: (selectedVariant) => void,
  setOptionsCallback?: (options) => void,
  fullProductData?: boolean,
  enableMobilePDPStickyAddToBasket?: boolean,
) => {
  const logger = useLogger()
  const { execute: executeAddToBasket } = useAddToBasket({
    initialSelectedOptions,
    setSelectedOptionsCallback,
    setSelectedVariantCallback,
    setOptionsCallback,
    fullProductData,
  })

  return (event: React.MouseEvent<Element, MouseEvent>) => {
    try {
      quickBuyModalDataTrackingCallbacks.modalClicked()
      quickBuyModalDataTrackingCallbacks.modalViewed()
      executeAddToBasket(
        [{ sku, quantity: 1 }],
        { fromRecommendations, location: InteractionLocation.PRODUCT_LIST },
        FulfilmentMethod.HomeDelivery,
        undefined,
        onBasketChangeBeacon,
        trackingUrl,
        undefined,
        enableMobilePDPStickyAddToBasket,
      )
      event.preventDefault()
    } catch (error) {
      logger.error(`ProductBlock onClick threw an error: ${error.stack}`)
    }
  }
}
