import * as React from 'react'
import handleViewport from 'react-in-viewport'

import {
  BreakpointArray,
  mediaQueryRenderer,
  mq,
  spacing,
  styled,
  Text,
  padding as Padding,
  margin,
  ZIndexLevel,
} from '@thg-commerce/gravity-theme'
import { Image, SafeHtml } from '@thg-commerce/gravity-elements'
import {
  CallbackSetEvent,
  PlacementContentType,
  QubitContentType,
  QubitViewType,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Qubit'
import { useLogger, useSiteConfig } from '@thg-commerce/enterprise-core'
import { css } from '@thg-commerce/enterprise-theme'
import { QubitBadgeStyle } from '@thg-commerce/enterprise-widget-product-list-page/theme'
import { Orientation } from '@thg-commerce/gravity-patterns/ImageGallery/types'
import { QubitMode } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/types'

import {
  sendImpression,
  sendPlacementImpressionEvents,
} from '../qubitImpressions'
import { useQubit } from '../../Qubit'

export type QubitBadgeProps = {
  productId: string
  placementId: string
  type: QubitViewType
  emitImpressionEvent?: (params: {
    logger: any
    eventType: CallbackSetEvent
    callbackData?: string
    callbackURL?: string
  }) => void
  badgeStyle?: QubitBadgeStyle
}

const BadgeMessageWrapper = styled.div<{
  badgeStyle?: QubitBadgeStyle
}>`
  display: flex;
  position: relative;
  align-items: center;
  ${(props) =>
    props.badgeStyle &&
    css`
      ${Padding(props.badgeStyle.container.padding.mobile)}
      ${mq(props.theme.breakpointUtils.map, 'md')} {
        ${Padding(props.badgeStyle.container.padding.desktop)}
      }
    `};
  ${(props) =>
    props.badgeStyle &&
    `
      border-radius: ${props.badgeStyle.messageWrapper.borderRadius};
      border: ${props.badgeStyle.messageWrapper.border};
      background-color: ${props.badgeStyle.messageWrapper.backgroundColor};
    `};
`

const BadgeMessage = styled(SafeHtml)<{ badgeStyle?: QubitBadgeStyle }>`
  ${(props) =>
    props.badgeStyle && margin(props.badgeStyle.messageWrapper.margin)}
  strong {
    ${Text('small', 'alternate')}
  }
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin: 0 ${spacing(1)};
    ${(props) =>
      props.badgeStyle &&
      Text(
        props.badgeStyle.message.textStyle.entry,
        props.badgeStyle.message.textStyle.style,
      )}
  }

  color: ${(props) => props.theme.colors.palette.greys.darker};
`

const StyledImage = styled(Image)`
  overflow: unset;
`

export const QubitBadgeContainer = styled.div<{
  isGrid: boolean
  orientation: BreakpointArray<Orientation>
  badgeStyle?: QubitBadgeStyle
  zIndex?: ZIndexLevel
}>`
  display: flex;
  justify-content: start;
  ${(props) =>
    props.badgeStyle &&
    css`
      max-width: ${props.badgeStyle.container.maxWidth};
      top: ${props.badgeStyle.container.position.top};
      left: ${props.badgeStyle.container.position.left};
      right: ${props.badgeStyle.container.position.right};
      bottom: ${props.badgeStyle.container.position.bottom};
    `}

  ${(props) =>
    !props.isGrid &&
    mediaQueryRenderer(props.orientation, (orientation) =>
      orientation === Orientation.HORIZONTAL
        ? `
        padding-left: ${spacing(13)};
      `
        : `
          padding-left: ${spacing(0)};
        `,
    )}

  position: absolute;
  width: 100%;
  z-index: ${(props) => (props.zIndex ? props.zIndex : ZIndexLevel.Lower)};
`
const BadgeContent = ({
  forwardedRef,
  onClick,
  content,
  badgeStyle,
}: {
  forwardedRef: React.RefObject<HTMLDivElement>
  onClick: () => void
  content?: QubitContentType | null
  badgeStyle?: QubitBadgeStyle
}) => {
  if (!content) {
    return <div ref={forwardedRef} />
  }
  return (
    <BadgeMessageWrapper
      onClick={onClick}
      ref={forwardedRef}
      role="button"
      badgeStyle={badgeStyle}
    >
      {content.imageUrl && (
        <StyledImage
          src={[
            {
              url: content.imageUrl,
            },
          ]}
          alt={content.message || ''}
          isAmp={false}
          width="24px"
          height="24px"
        />
      )}
      {content.message && (
        <BadgeMessage content={content.message} badgeStyle={badgeStyle} />
      )}
    </BadgeMessageWrapper>
  )
}

const BadgeViewport = handleViewport(
  BadgeContent,
  {},
  { disconnectOnLeave: true },
)

export const QubitBadge = (props: QubitBadgeProps) => {
  const logger = useLogger()
  const { qubit } = useSiteConfig()
  const impressionEmittedRef = React.useRef(false)

  const data = useQubit({
    mode: qubit?.mode || QubitMode.LIVE,
    type: props.type || QubitViewType.CATEGORY,
    placementId: props.placementId,
    productId: props.productId,
  })

  if (!data?.qubitPlacements) {
    return null
  }

  const content =
    data.qubitPlacements.content &&
    data.qubitPlacements.content[PlacementContentType.BADGING]

  const onEnterViewport = () => {
    if (impressionEmittedRef.current) {
      return
    }

    sendPlacementImpressionEvents({
      logger,
      sendPlacementLevelImpression: props.emitImpressionEvent,
      callbackData: data.qubitPlacements?.callbackData,
      callbackURL: qubit?.callbackURL,
      productId: props.productId,
    })
    impressionEmittedRef.current = true
  }

  return (
    <React.Fragment>
      <BadgeViewport
        content={content}
        badgeStyle={props.badgeStyle}
        onEnterViewport={onEnterViewport}
        onClick={() =>
          sendImpression({
            logger,
            sku: props.productId,
            eventType: CallbackSetEvent.CLICK_THROUGH,
            callbackData: data.qubitPlacements?.callbackData,
            callbackURL: qubit?.callbackURL,
          })
        }
      />
    </React.Fragment>
  )
}
