import { CallbackSetEvent } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Qubit'

const getMultipleProductSkuParams = (skus: string[]) => {
  const formattedSkus = skus.map((sku) => `&p=${sku}`)

  return formattedSkus.join('')
}

export const sendImpression = ({
  sku,
  eventType,
  callbackData,
  callbackURL,
  logger,
}: {
  sku?: string | string[]
  eventType: CallbackSetEvent
  callbackData?: string
  callbackURL?: string
  logger
}) => {
  if (!callbackData || !callbackURL) {
    return
  }

  const timestamp = Date.now()

  let qubitCallbackURL = callbackURL
    .replace('{callbackData}', `${callbackData}`)
    .replace('{timestamp}', `${timestamp}`)
    .replace('{eventType}', `${eventType.toLowerCase()}`)

  qubitCallbackURL = !sku
    ? qubitCallbackURL.replace('&p={sku}', '')
    : Array.isArray(sku)
    ? qubitCallbackURL.replace('&p={sku}', getMultipleProductSkuParams(sku))
    : qubitCallbackURL.replace('{sku}', sku)

  fetch(qubitCallbackURL, {
    method: 'GET',
    keepalive: true,
  })
    .then(() => {
      return
    })
    .catch((error) => {
      logger.warn(
        `Failed to send Qubit callback request to qubitCallbackURL: ${qubitCallbackURL} with error: ${error.message}`,
      )
    })
}

export const sendPlacementImpressionEvents = ({
  sendPlacementLevelImpression,
  callbackData,
  callbackURL,
  productId,
  logger,
}: {
  sendPlacementLevelImpression?: (params: {
    logger: any
    eventType: CallbackSetEvent
    callbackData?: string
    callbackURL?: string
  }) => void
  callbackData?: string
  callbackURL?: string
  logger: any
  productId?: string | string[]
}) => {
  if (!callbackData || !callbackURL) {
    return
  }

  sendPlacementLevelImpression?.({
    logger,
    callbackData,
    callbackURL,
    eventType: CallbackSetEvent.IMPRESSION,
  })

  sendImpression({
    logger,
    callbackData,
    callbackURL,
    sku: productId,
    eventType: CallbackSetEvent.IMPRESSION,
  })
}
