import * as React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { PowerReview } from '@thg-commerce/enterprise-components'
import { useTheme } from '@thg-commerce/enterprise-core'
import { mq, styled } from '@thg-commerce/enterprise-theme'

import { PowerReviewComponentType, PowerReviewProps } from '../src/PowerReview'

const PowerReviewCategorySnippetContainer = styled.div<{
  displayStyle?: string
}>`
  .p-w-r {
    .pr-no-reviews {
      display: ${(props) => props.displayStyle ?? 'none'};
    }

    .pr-category-snippet {
      display: flex;
      flex-direction: column;

      ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
        flex-direction: row;
        align-items: center;
      }
    }
  }
`

export const PowerReviewCategorySnippet = (props: PowerReviewProps) => {
  const uuid = uuidv4()
  const theme = useTheme()
  return (
    <PowerReviewCategorySnippetContainer
      displayStyle={
        theme.patterns.productBlock?.components?.powerReviewCategorySnippet
          ?.displayStyle
      }
    >
      <PowerReview
        sku={props.sku}
        externalId={props.externalId}
        locale={props.locale}
        id={`pr-category-snippet-${props.id ?? uuid}`}
        component={PowerReviewComponentType.REVIEW_CATEGORY_SNIPPET}
        pathname={props.pathname}
        powerReviewGroups={props.powerReviewGroups}
      />
    </PowerReviewCategorySnippetContainer>
  )
}
