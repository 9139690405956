import * as React from 'react'
import { ProductClearanceFlag } from '@thg-commerce/gravity-elements/Product/ProductClearanceFlag'
import { ContentItem } from '../Product/src/ProductBlock'

interface ProductClearanceProps {
  content?: ContentItem[]
  order?: number
}

export const ProductClearance = (props: ProductClearanceProps) => {
  const clearanceTagValue = props?.content?.find(
    (contentItem) => contentItem.key === 'hbg_priceType',
  )

  const clearanceTag =
    (clearanceTagValue &&
      (clearanceTagValue.value as {
        __typename: 'ProductContentStringValue'
        stringValue: string
      }).stringValue) ||
    ''

  if (clearanceTag === 'CLEARANCE') {
    return (
      <ProductClearanceFlag
        content={clearanceTag}
        order={props.order}
        maxWidth="36%"
      />
    )
  }
  return null
}
