import * as React from 'react'
import handleViewport from 'react-in-viewport'

import {
  FulfilmentMethodIconsRenderer,
  FulfilmentMethodIconsRendererProps,
} from '@thg-commerce/enterprise-components/FulfilmentMethods/FulfilmentMethodIconsRenderer'
import { PricePerUnit } from '@thg-commerce/enterprise-components/PricePerUnit'
import { ProductClearance } from '@thg-commerce/enterprise-components/ProductClearance'
import { QubitBadgeRenderer } from '@thg-commerce/enterprise-components/Qubit/QubitBadge/QubitBadgeRenderer'
import {
  useExperiments,
  useSessionSettings,
  useSiteConfig,
  useSiteDefinition,
} from '@thg-commerce/enterprise-core'
import {
  MultipleEvents,
  productEventsCategory,
  pushToEventGA4,
} from '@thg-commerce/enterprise-metrics/src/data_layer/pushToDataLayer/utils'
import {
  CallbackSetEvent,
  QubitViewType,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Qubit'
import {
  FulfilmentMethod,
  InStockLocation,
  ProductContentItem,
  ProductContentStringListValue,
  ProductContentStringValue,
  ProductContentValue,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { Beacon } from '@thg-commerce/enterprise-network/src/transformers/sponsoredAds/products'
import { SelectedOptions } from '@thg-commerce/enterprise-product-options/src'
import { ProductPriceProps } from '@thg-commerce/gravity-elements'
import { useTheme } from '@thg-commerce/gravity-elements/theme'
import { ProductBlock as GravityProductBlock } from '@thg-commerce/gravity-patterns'
import { ProductBlockProps as GravityProductBlockProps } from '@thg-commerce/gravity-patterns/ProductBlock/ProductBlock'
import {
  BreakpointArray,
  Orientation,
  ZIndexLevel,
} from '@thg-commerce/gravity-theme'

import { PapBadgeRenderer } from '../../../PapOverlayBadge'
import { PowerReviewCategorySnippet } from '../../../PowerReviewCategorySnippet'
import { removeCurrencySymbol } from '../../../Price'
import { getPowerReviewGroups } from '../../../src/PowerReview/PowerReview'

import { useQuickBuyClickHandler } from './hooks'
import { eventEmitterGA4, EventEmitterType } from './utils'

export interface ContentItem extends Omit<ProductContentItem, 'value'> {
  value:
    | ProductContentValue
    | {
        __typename: 'ProductContentStringListValue'
        stringListValue: ProductContentStringListValue['value']
      }
    | {
        __typename: 'ProductContentStringValue'
        stringValue: ProductContentStringValue['value']
      }
}

export type ProductBlockProps = GravityProductBlockProps &
  ProductPrice & {
    sku: string
    button?: Omit<GravityProductBlockProps['button'], 'onClick'> & {
      quickBuyTitle: string
      productInStock?: boolean
    }
    emitImpressionEvent?: (params: {
      logger: any
      eventType: CallbackSetEvent
      callbackData?: string
      callbackURL?: string
    }) => void
    fromRecommendations?: boolean
    onQuickBuyClickEventEmitter?: () => void
    fulfilmentMethodIconsProps?: FulfilmentMethodIconsRendererProps
    externalIdentifier?: string
    powerReviewGroups?: string
    qubitBadgeStyle?: QubitBadgeStyle
    papBadgeStyle?: PapBadgeStyle
    qubitBadgeZIndex?: ZIndexLevel
    displayQuickBuyButton?: Boolean
    label?: string
    eligibleForFulfilmentMethods?: FulfilmentMethod
    weightGroups?: string[]
    content?: ContentItem[]
    inStockLocations?: InStockLocation[]
    leadTime?: number
    isBookable?: boolean
    loopSlides?: boolean
    sponsoredAdsBeacons?: {
      onBasketChangeBeacon?: Beacon
      onClickBeacon?: Beacon
      onViewBeacon?: Beacon
    }
    hideGap?: boolean
    enableQuickbuyButtonOnDesktop?: boolean
    listPageTitle?: string
    selectedOptions?: SelectedOptions
    index?: number
    pricePerUnit?: {
      displayValue?: string
      unit?: string
    }
  }

interface ProductPrice {
  price?: ProductPriceProps
}

export const ProductBlock = (props: ProductBlockProps) => {
  return <EnterpriseProductBlock {...props} />
}

const SponsoredAdsFireInViewElement = (props: {
  inViewport: boolean
  enterCount: number
  onViewBeacon: Beacon
  forwardedRef: React.RefObject<HTMLDivElement>
}) => {
  const shouldFireBeacons =
    props.inViewport && props.enterCount && props.enterCount < 2

  if (props.onViewBeacon && shouldFireBeacons) {
    navigator.sendBeacon(props.onViewBeacon.url)
  }
  return <div style={{ position: 'absolute' }} ref={props.forwardedRef}></div>
}

const SponsoredAdsFireInViewportElement = handleViewport(
  SponsoredAdsFireInViewElement,
)

const shouldRenderQuickBuyButton = (
  hideProductListProductBlockButton?: boolean,
  quickbuyExperiment?: string,
) => {
  return (
    !hideProductListProductBlockButton ||
    quickbuyExperiment === 'v1|show_quickbuy'
  )
}

const getTagStyle = (imageTagStyle, enableSmallQuickBuyButton, button) => {
  if (!imageTagStyle) {
    return
  }

  return {
    ...imageTagStyle,
    container: {
      ...imageTagStyle.container,
      width:
        enableSmallQuickBuyButton && button?.mobileSingleColumn
          ? ['92%', '100%', '100%', '100%']
          : imageTagStyle.container?.width || ['100%'],
    },
  }
}

const PowerReviewCategorySnippetComponent = (
  props: ProductBlockProps,
  defaultLocale: string,
  showPowerReview?: boolean,
) => {
  if (showPowerReview) {
    return (
      <PowerReviewCategorySnippet
        sku={parseInt(props.sku, 10)}
        externalId={parseInt(props.externalIdentifier ?? '', 10)}
        locale={defaultLocale}
        pathname={props.url}
        id={props.sku}
        powerReviewGroups={getPowerReviewGroups(props.content)}
      />
    )
  }

  return null
}

const EnterpriseProductBlock = React.memo((props: ProductBlockProps) => {
  const { displayQuickBuyButton = true } = props
  const { defaultLocale, domain } = useSiteDefinition()
  const { quickBuyTitle: quickBuyButtonTitle, productInStock } = {
    ...props.button,
  }
  const quickBuyClickHandler = useQuickBuyClickHandler(
    props.sku,
    props.fromRecommendations,
    props.sponsoredAdsBeacons?.onBasketChangeBeacon,
    `https://${domain}${props.url}`,
    props.selectedOptions,
  )
  const {
    showProductBlockBrandTitle,
    showProductBlockBrandLogo,
    hideProductListSwatch,
    previewQuickbuy,
    enableSmallQuickBuyButton,
    hideProductListProductBlockButton,
    enableQuickbuyOnlyOnMobile,
    showPowerReview,
    useGA4EnhancedEcom,
    enablePricePerUnit,
    showFlagsOnProduct,
    inlineQuickBuyButton,
    enableSponsoredAds,
    useExternalIdentifier,
    useExternalIdentifierInSchema,
    enablePapOverlay,
    enableSkuOnPowerReviews,
    displaySwatchesOnOneRow,
  } = useSiteConfig()
  const experiments = useExperiments()

  const theme = useTheme()
  const sessionSettings = useSessionSettings()

  const quickBuyEnabled =
    previewQuickbuy && productInStock && displayQuickBuyButton

  const buttonClickHandler = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.stopPropagation()
    quickBuyClickHandler(event)
    props.onQuickBuyClickEventEmitter?.()
  }

  const { papBadgeStyle, qubitBadgeStyle } = props

  const annotateImageComponent = React.useMemo(() => {
    const BadgeAnnotation = ({ children }) => {
      return (
        <div style={{ position: 'relative' }}>
          <PapBadgeRenderer
            badgeStyle={props.papBadgeStyle}
            marketedSpecialOfferTitleText={
              props.marketedSpecialOffer?.title || ''
            }
          />
          {children}
        </div>
      )
    }

    const QubitBadgeAnotation = ({ children }) => {
      const orientation: BreakpointArray<Orientation> = [
        Orientation.VERTICAL,
        Orientation.VERTICAL,
        Orientation.HORIZONTAL,
        Orientation.HORIZONTAL,
      ]
      return (
        <div id="placeholder" style={{ position: 'relative' }}>
          <QubitBadgeRenderer
            emitImpressionEvent={props.emitImpressionEvent}
            productId={props.sku.toString()}
            type={QubitViewType.CATEGORY}
            orientation={orientation}
            badgeStyle={props.qubitBadgeStyle}
            zIndex={props.qubitBadgeZIndex}
          />
          {children}
        </div>
      )
    }

    let componentToReturn: any = undefined
    if (papBadgeStyle && enablePapOverlay) componentToReturn = BadgeAnnotation
    else if (qubitBadgeStyle) componentToReturn = QubitBadgeAnotation
    return componentToReturn
  }, [
    papBadgeStyle,
    enablePapOverlay,
    qubitBadgeStyle,
    props.emitImpressionEvent,
    props.sku,
    props.qubitBadgeStyle,
    props.qubitBadgeZIndex,
    props.papBadgeStyle,
    props.marketedSpecialOffer?.title,
  ])

  return (
    <GravityProductBlock
      {...props}
      attributes={
        props.hideGap
          ? {
              gap: [0],
            }
          : undefined
      }
      productVariant={props.productVariant}
      image={props.image}
      label={props.label}
      showBrandTitle={showProductBlockBrandTitle}
      showBrandLogo={showProductBlockBrandLogo}
      hideProductListSwatch={hideProductListSwatch}
      annotateImageComponent={annotateImageComponent}
      enableSmallQuickBuyButton={
        enableSmallQuickBuyButton && !props.enableQuickbuyButtonOnDesktop
      }
      inlineQuickBuyButton={inlineQuickBuyButton}
      disableHeightPowerReviews={enableSkuOnPowerReviews}
      handleEvents={() =>
        useGA4EnhancedEcom &&
        pushToEventGA4<MultipleEvents>({
          event: 'ecom_event',
          event_name: 'Pap | papBanner',
          promotion_name: 'Offer',
          creative_slot: 'papBanner Component',
          promotion_id: null,
          item: {
            item_id: props.sku.toString(),
            item_external_id: props.externalIdentifier
              ? props.externalIdentifier
              : props.sku.toString(),
            item_name: props.title?.value || '',
            item_brand: props.brand?.name || '',
            item_currency: sessionSettings?.currency,
            index: props.index?.toString() || '',
            item_page: props.listPageTitle || props.title.value,
            price: removeCurrencySymbol(props.price?.price?.defaultPrice) || '',
            item_promotion: 'Offer',
          },
        })
      }
      onClickEventEmitter={() => {
        if (useGA4EnhancedEcom) {
          eventEmitterGA4(
            EventEmitterType.SELECT_ITEM,
            sessionSettings,
            props.title,
            props.price,
            props.brand,
            props.sku,
            props.listPageTitle,
            props.externalIdentifier,
            props.index,
            useExternalIdentifier,
            useExternalIdentifierInSchema,
            props.defaultVariant,
            props.productVariant,
          )

          !!props.marketedSpecialOffer?.title &&
            eventEmitterGA4(
              EventEmitterType.SELECT_PROMOTION,
              sessionSettings,
              props.title,
              props.price,
              props.brand,
              props.sku,
              props.listPageTitle,
              props.externalIdentifier,
              props.index,
              useExternalIdentifier,
              useExternalIdentifierInSchema,
              props.defaultVariant,
              props.productVariant,
            )
        }
        if (enableSponsoredAds && props.sponsoredAdsBeacons?.onClickBeacon) {
          navigator.sendBeacon(props.sponsoredAdsBeacons.onClickBeacon.url)
        }
      }}
      showPowerReview={showPowerReview}
      powerReviewCategorySnippetComponent={PowerReviewCategorySnippetComponent(
        props,
        defaultLocale,
        showPowerReview,
      )}
      externalIdentifier={props.externalIdentifier}
      button={
        shouldRenderQuickBuyButton(
          hideProductListProductBlockButton,
          experiments['web_quickbuy_cta'],
        ) && props.button
          ? {
              ...props.button,
              enableQuickbuyOnlyOnMobile:
                enableQuickbuyOnlyOnMobile &&
                !props.enableQuickbuyButtonOnDesktop,
              title:
                quickBuyEnabled && quickBuyButtonTitle
                  ? quickBuyButtonTitle
                  : props.button.title,
              onClick: quickBuyEnabled ? buttonClickHandler : undefined,
            }
          : undefined
      }
      icons={
        props.fulfilmentMethodIconsProps?.hasClickAndCollect ? (
          <FulfilmentMethodIconsRenderer
            {...props.fulfilmentMethodIconsProps}
          />
        ) : undefined
      }
      pricePerUnitContent={
        enablePricePerUnit ? (
          <PricePerUnit pricePerUnit={props.pricePerUnit} />
        ) : undefined
      }
      content={
        <React.Fragment>
          {showFlagsOnProduct ? (
            <ProductClearance content={props?.content} />
          ) : undefined}
          {enableSponsoredAds ? (
            <SponsoredAdsFireInViewportElement
              onViewBeacon={props.sponsoredAdsBeacons?.onViewBeacon}
            />
          ) : undefined}
        </React.Fragment>
      }
      loopSlides={props.loopSlides}
      enablePapOverlay={enablePapOverlay}
      displaySwatchesOnOneRow={displaySwatchesOnOneRow}
      useGA4EnhancedEcom={useGA4EnhancedEcom}
      tagStyle={getTagStyle(
        theme.elements.productImage.imageTagStyle,
        enableSmallQuickBuyButton,
        props.button,
      )}
    />
  )
})
