import { RenderAnnouncerType } from '@thg-commerce/gravity-elements'
import { BreakpointArray, Direction } from '@thg-commerce/gravity-theme'

import {
  AmpCarouselProps,
  CarouselButtonPlacement,
  CarouselI18nText,
} from '../Carousel/types'

export interface ProductImageUrls {
  thumbnail?: string
  largeProduct?: string
  zoom?: string
}

export interface FullscreenComponentProp {
  fullscreen?: boolean
}

export interface ImageThumbnailsProps extends FullscreenComponentProp {
  urls: { url: string; alt: string }[]
  selectedImageIndex: number
  onSelect?: (index: number) => void
  thumbnailButtonAriaLabel: string
  altText: string
  direction: BreakpointArray<Direction>
  size: BreakpointArray<number>
  fullScreen: boolean
  aspectRatio?: string
}

export interface ImageCarouselProps extends FullscreenComponentProp {
  urls: ProductImageUrls[]
  i18nText: CarouselI18nText
  ampProps?: AmpCarouselProps
  altText: string
  slideTo?: number
  onChange?: (index: number) => void
}

export enum Orientation {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum Position {
  TOP_RIGHT = 'TOP_RIGHT',
  BOTTOM_RIGHT_SPACED = 'BOTTOM_RIGHT_SPACED',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  TOP_LEFT = 'TOP_LEFT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  TOP_MIDDLE = 'TOP_MIDDLE',
}

export interface ThumbnailPadding {
  xs?: string
  sm?: string
  md?: string
  lg?: string
}

export interface ImageGalleryProps {
  urls: {
    thumbnails: { url: string; alt: string }[]
    images: { url: string; alt: string }[]
    zoom: { url: string; alt: string }[]
  }
  i18nText: {
    carouselI18nText?: CarouselI18nText
    thumbnailButtonAriaLabel?: string
    fullscreenI18nText?: {
      enterFullscreenText: string
      exitFullscreenText: string
    }
    zoomOutLabel?: string
    zoomInLabel?: string
    fullscreenIndexI18nText?: string
    announcerI18nText: {
      imageChangeAnnouncerText: string
      zoomLevelAnnouncerText: string
      fullscreenEnterAnnouncerText: string
      fullscreenExitAnnouncerText: string
      panI18nText: {
        imagePanAnnouncerText: string
        leftText: string
        rightText: string
        downText: string
        upText: string
      }
    }
    accessibilityLabel: string
  }
  ampProps?: AmpCarouselProps
  altText: string
  renderAnnouncer?: RenderAnnouncerType
  direction: BreakpointArray<Direction>
  thumbnailSize: BreakpointArray<number>
  thumbnailsMarginX?: BreakpointArray<number>
  thumbnailPadding?: ThumbnailPadding
  controlSize: BreakpointArray<number>
  controlBorderColor?: string
  controlBackgroundColor?: string
  zoomControlPosition: BreakpointArray<Position>
  zoomControl?: boolean
  fullscreenControlPosition: BreakpointArray<Position>
  showCarouselControls?: BreakpointArray<boolean>
  showFooter?: BreakpointArray<boolean>
  aspectRatio?: string
  height?: BreakpointArray<number>
  style?: React.CSSProperties
  className?: string
  itemsPerSlide?: number | number[]
  gapSpacing: BreakpointArray<number>
  carousel?: {
    itemGapSpacing?: number
    controls?: {
      visible?: BreakpointArray<boolean>
      placement?: CarouselButtonPlacement
      size: number
    }
    overflow?: BreakpointArray<string>
    onHydrateVisibleFetchPriority?: string
  }
  zoomEnabled?: boolean
  desktopZoomEnabled?: boolean
  presentImageGalleryZoomModal?: boolean
  zoomOnClickHandler?: (imageIndex: number) => void
  selectedImageIndex?: number
  controlIcons?: {
    zoomIn?: string
    zoomOut?: string
    fill: string
  }
  onSwipe?: () => void
  onNavClick?: (direction: 'left' | 'right') => void
  onThumbnailClick?: () => void
  updatingUrlsResetSelectedImageIndex?: boolean
}

export enum Paginate {
  Previous = -1,
  Next = 1,
}

export enum FullscreenHeaderTitleTabIndex {
  NotTabbable = -1,
  Tabbable = 0,
}
export interface ImageGalleryThemeInterface {
  image: {
    aspectRatio: string
  }
}
