import * as React from 'react'

import { styled, Text } from '@thg-commerce/enterprise-theme'
import { formatPrice } from '@thg-commerce/enterprise-pricing'
import { useSiteDefinition, useLogger } from '@thg-commerce/enterprise-core'

const PriceElement = styled.span<{ useDefaultStyle?: boolean }>`
  ${(props) =>
    Text('bodyText', props.useDefaultStyle ? 'default' : 'alternate')}
`

export const removeCurrencySymbol = (price: string | undefined) => {
  if (typeof price === 'string') {
    return price.replace(/[^0-9\.-]+/g, '')
  }

  return price
}

export interface PriceProps {
  value: string
  locale?: string
  className?: string
  useDefaultStyle?: boolean
}

export const Price = (props: PriceProps) => {
  const { defaultLocale = 'en_GB' } = useSiteDefinition()
  const logger = useLogger()

  if (typeof props?.value !== 'string') {
    logger.debug('Could not format price given the following - undefined')
    return <PriceElement className={props?.className} />
  }

  const currencyCode = props.value.substr(0, 3)
  const priceValue = props.value.substr(3, props.value.length)
  const formattedPrice =
    RegExp(/^[a-zA-Z]+$/).test(currencyCode) &&
    !isNaN(parseFloat(priceValue)) &&
    formatPrice(
      currencyCode,
      parseFloat(priceValue),
      props.locale || defaultLocale,
    )

  if (formattedPrice) {
    return (
      <PriceElement
        useDefaultStyle={props.useDefaultStyle || false}
        className={props.className}
      >
        {formattedPrice}
      </PriceElement>
    )
  }

  logger.debug(
    `Could not format price given the follwing - value: ${
      props.value
    } locale: ${props.locale || defaultLocale}`,
  )
  return (
    <PriceElement
      useDefaultStyle={props.useDefaultStyle}
      className={props.className}
    >
      {props.value}
    </PriceElement>
  )
}
