import * as React from 'react'

import { useSiteConfig } from '@thg-commerce/enterprise-core'
import { PapBadges } from '@thg-commerce/enterprise-widget-product-list-page/theme'
import {
  css,
  spacing,
  styled,
  Text,
  ZIndexLevel,
} from '@thg-commerce/gravity-theme'

const ProductCardContainer = styled.div`
  position: absolute;
  top: ${spacing(1)};
  z-index: ${ZIndexLevel.Low};
`
interface ProductCardInterface {
  badgeStyle?: PapBadges
  useAlternate: boolean
}

const ProductCardAlternate = ({
  useAlternate,
  badgeStyle,
}: ProductCardInterface) =>
  useAlternate &&
  badgeStyle?.alternate &&
  css`
    background: ${badgeStyle.alternate.backgroundColor};
    color: ${badgeStyle.alternate.textStyle.textColor};
  `

const ProductCard = styled.div<ProductCardInterface>`
  ${Text('xsmall', 'alternate')};
  background: ${(props) => props.badgeStyle?.base.backgroundColor};
  color: ${(props) => props.badgeStyle?.base.textStyle.textColor};
  position: absolute;
  vertical-align: middle;
  width: max-content;
  left: -0.375rem;
  padding: ${spacing(0.5)};
  ${ProductCardAlternate}
`

export const PapBadgeRenderer = ({
  marketedSpecialOfferTitleText,
  badgeStyle,
}: {
  marketedSpecialOfferTitleText: string
  badgeStyle?: PapBadges
}) => {
  const {
    enablePapOverlay,
    papProductItemHighlightedTitleList,
  } = useSiteConfig()

  if (
    !enablePapOverlay ||
    !papProductItemHighlightedTitleList ||
    !marketedSpecialOfferTitleText
  ) {
    return null
  }

  return (
    <ProductCardContainer>
      <ProductCard
        badgeStyle={badgeStyle}
        useAlternate={papProductItemHighlightedTitleList.includes(
          marketedSpecialOfferTitleText,
        )}
      >
        {marketedSpecialOfferTitleText}
      </ProductCard>
    </ProductCardContainer>
  )
}
