import * as React from 'react'
import { styled } from '@thg-commerce/gravity-theme'
import { Order } from '@thg-commerce/gravity-patterns/ProductBlock/types'

export interface ProductClearanceFlagProps {
  content: string
  maxWidth?: string
  order?: Order
}

const ClearanceWrapper = styled.div<ProductClearanceFlagProps>`
  margin-bottom: 10px;
  order: ${(props) => props.order || Order.FIVE};
`

// TODO Remove fontsize etc use Text function - CSBOM-329
const ClearanceContent = styled.div`
  font-size: ${(props) =>
    props.theme.elements.productClearance.fontStyle.fontSize};
  line-height: 16px;
  font-weight: ${(props) =>
    props.theme.elements.productClearance.fontStyle.fontWeight};
  letter-spacing: 0.3px;
  border: ${(props) => props.theme.elements.productClearance.border};
  color: ${(props) => props.theme.elements.productClearance.color};
  text-transform: uppercase;
  padding: ${(props) => props.theme.elements.productClearance.padding};
  background-color: ${(props) =>
    props.theme.elements.productClearance.backgroundColor};
  display: inline-block;
`

export const ProductClearanceFlag = (props: ProductClearanceFlagProps) => {
  return (
    <ClearanceWrapper {...props}>
      <ClearanceContent>{props.content}</ClearanceContent>
    </ClearanceWrapper>
  )
}
