import * as React from 'react'
import * as Cookies from 'js-cookie'

import { useSiteConfig } from '@thg-commerce/enterprise-core'
import { BreakpointArray, ZIndexLevel } from '@thg-commerce/gravity-theme'
import { QubitBadgeStyle } from '@thg-commerce/enterprise-widget-product-list-page/theme'
import { Orientation } from '@thg-commerce/gravity-patterns/ImageGallery/types'

import { QubitBadgeContainer, QubitBadge, QubitBadgeProps } from './QubitBadge'

export type QubitBadgeRendererProps = Omit<QubitBadgeProps, 'placementId'> & {
  isGrid?: boolean
  orientation: BreakpointArray<Orientation>
  badgeStyle?: QubitBadgeStyle
  zIndex?: ZIndexLevel
}

export const QubitBadgeRenderer = (props: QubitBadgeRendererProps) => {
  const { qubit } = useSiteConfig()

  if (
    !qubit?.enabled ||
    !qubit?.placements?.badgingId ||
    !Cookies.get('_qubitTracker')
  ) {
    return null
  }

  return (
    <QubitBadgeContainer
      isGrid={!!props.isGrid}
      orientation={props.orientation}
      badgeStyle={props.badgeStyle}
      zIndex={props.zIndex}
    >
      <QubitBadge
        emitImpressionEvent={props.emitImpressionEvent}
        type={props.type}
        productId={props.productId}
        placementId={qubit.placements.badgingId}
        badgeStyle={props.badgeStyle}
      />
    </QubitBadgeContainer>
  )
}
