import { useQuery } from '@apollo/react-hooks'
import { useRouter } from 'next/compat/router'

import {
  useSessionSettings,
  useSiteDefinition,
  uuid,
} from '@thg-commerce/enterprise-core'
import { QubitMode } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/types'
import { setCookie } from '@thg-commerce/enterprise-core/src/utils/CookieHelper'
import { CookieGroup } from '@thg-commerce/enterprise-core/src/utils/CookieHelper/types'
import {
  PlacementContent,
  PlacementContentArgs,
  QubitViewType,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/Qubit'
import { PlacementContent as QUERY } from '@thg-commerce/enterprise-network/src/graphql/Query/Qubit/PlacementContent.graphql'
import { sanitizeCookie } from '@thg-commerce/enterprise-utils'

import { parseQubitPreviewParams } from './utils'

interface UseQubitProps {
  type: QubitViewType
  placementId: string
  mode: QubitMode
  productId?: string
  skuList?: string[]
  subtypes?: string[]
  skip?: boolean
}

export const useQubit = ({
  type,
  placementId,
  mode,
  productId,
  subtypes,
  skip,
}: UseQubitProps) => {
  const { domain, defaultLocale } = useSiteDefinition()
  const { currency } = useSessionSettings()
  const router = useRouter()

  if (
    typeof document !== 'undefined' &&
    !document.cookie.includes('_qubitTracker')
  ) {
    setCookie({
      domain,
      value: uuid.generate(),
      name: '_qubitTracker',
      group: CookieGroup.REQUIRED,
    })
  }

  const visitorId = sanitizeCookie('_qubitTracker')

  if (!visitorId) {
    return null
  }

  const {
    mode: previewMode,
    placementId: previewPlacementId,
    ...previewParams
  } = parseQubitPreviewParams(window.location.href)

  const locale = defaultLocale.replace(/_/g, '-').toLowerCase()

  const { data } = useQuery<
    { qubitPlacements: PlacementContent | null },
    PlacementContentArgs
  >(QUERY, {
    variables: {
      placementId: previewPlacementId ?? placementId,
      mode: previewMode ?? mode,
      previewOptions: {
        experienceId: previewParams.experienceId,
        campaignId: previewParams.campaignId,
        group: previewParams.group,
      },
      attributes: {
        visitor: {
          id: visitorId,
          url: `${domain}${router.asPath}`,
        },
        user: {},
        product: { id: productId },
        view: {
          currency,
          type,
          subtypes: subtypes || [],
          language: locale,
        },
      },
      resolveVisitorState: true,
      recsPreFilter: false,
    },
    fetchPolicy: 'no-cache',
    skip: skip || window.location.hash.includes('#qb_opts'),
  })

  return data
}
