import { QubitMode } from '@thg-commerce/enterprise-core/src/ConfigurationLoader/types'
import { Currency } from '../../../generated/graphql'

export enum PlacementContentType {
  RECOMMENDATIONS = 'recommendations',
  BADGING = 'badging',
  PERSONALISED_CONTENT = 'personalisedContent',
}
interface PlacementType {
  [PlacementContentType.RECOMMENDATIONS]?: RecommendationsContent
  [PlacementContentType.BADGING]?: QubitContentType
  [PlacementContentType.PERSONALISED_CONTENT]?: PersonalisedContentType
}
export interface PlacementContent {
  content: PlacementType | null
  callbacks: CallbackSet
  callbackData: string
  visitorId: string
  type?: PlacementContentType
  __typename?: string
}

export interface QubitApiResponse {
  content:
    | BadgeContent
    | RecommendationsContent
    | PersonalisedContentType
    | {
        imageUrl: string | null
        message: string | null
      }
    | null
  callbacks: CallbackSet
  callbackData: string
  visitorId: string
  __typename?: string
}
export interface BadgeContent {
  config: PlacementConfig
  content: {
    imageUrl: string | null
    message: string | null
  }
}

export interface QubitContentType {
  config?: PlacementConfig
  imageUrl: string | null
  message: string | null
}

export interface RecommendationsContent {
  headline: string | null
  recs: QubitRecommendation[]
}

export interface PersonalisedContentType {
  title: string
  copy: string
  cta: string
  style?: string | null
  horizontalImageSrc: string
  verticalImageSrc?: string | null
  ctaText: string
}

interface QubitRecommendation {
  details: {
    base_currency: Currency
    categories: string[]
    currency: string
    description: string
    id: string
    image_url: string
    images: string[]
    language: string
    locale: string
    name: string
    product_id: string
    product_stock: number
    sku_code: string
    stock: number
    unit_base_price: number
    unit_price: number
    unit_sale_base_price: number
    unit_sale_price: number
    url: string
    views: number
    views_ip: number
  }
  id: string
  strategy: string
  weight: number
}

export interface QubitPreviewType {
  preview?: boolean
  remember?: boolean | string[]
  placementId?: string
  campaignId?: string
  experienceId?: string
  group?: CampaignVariantGroup
  mode?: QubitMode
  experiences?: string[]
}

export interface PlacementConfig {
  counter: string
  max: number
  op: string
  threshold: 1
  timeframe: QubitTimeframes
}

export enum QubitTimeframes {
  LAST_HOUR = 'In the last hour',
  LAST_DAY = 'In the last day',
  LAST_WEEK = 'In the last week',
  LAST_2_DAYS = 'In the last 2 days',
  LAST_3_DAYS = 'In the last 3 days',
}
export type CallbackSet = {
  impression: string
  clickthrough: string
}

export enum CallbackSetEvent {
  IMPRESSION = 'IMPRESSION',
  CLICK_THROUGH = 'CLICKTHROUGH',
}

export interface PlacementContentArgs {
  mode: QubitMode
  placementId: string
  implementationId?: string
  previewOptions?: PreviewOptions
  attributes: Attributes
  resolveVisitorState: boolean
  recsPreFilter: boolean
}

export enum Mode {
  PREVIEW = 'PREVIEW',
  SAMPLE = 'SAMPLE',
  LIVE = 'LIVE',
  NULL = 'NULL',
}

export enum CampaignVariantGroup {
  TEST = 'TEST',
  BASELINE = 'BASELINE',
}

export type Attributes = {
  visitor?: Visitor
  user?: User | {}
  view?: View
  product?: QubitProduct
  listingProducts?: QubitProduct[]
  basketProducts?: QubitProduct[]
  transactionProducts?: QubitProduct[]
}

type Visitor = {
  id: string
  viewNumber?: number
  sessionNumber?: number
  entranceNumber?: number
  sessionViewNumber?: number
  conversionNumber?: number
  lifetimeValue?: number
  firstViewTs?: number
  lastViewTs?: number
  firstConversionTs?: number
  lastConversionTs?: number
  ipAddress?: string
  url?: string
  userAgent?: UserAgent
  location?: Location
}

type QubitProduct = {
  id?: string
  name?: string
  categories?: string[]
}

type UserAgent = {
  browserName: string
  browserVersion: string
  deviceName: string
  deviceType: string
  osName: string
  osVersion: string
  userAgent: string
}

type User = {
  id: string
  email: string
  loyalty: Loyalty
}

type Location = {
  areaCode: string
  cityCode: string
  regionCode: string
  countryCode: string
}

type Loyalty = {
  membershipType: string
}

type View = {
  currency: string
  type: string
  subtypes: string[]
  language: string
}

export enum QubitViewType {
  PRODUCT = 'product',
  CATEGORY = 'category',
  HOME = 'home',
  BASKET = 'basket',
  SEARCH = 'search',
}

export type PreviewOptions = {
  campaignId?: string
  experienceId?: string
  demoMode?: boolean
  group?: CampaignVariantGroup
}
