import dynamic from 'next/dynamic'

import { TextEntry, TextStyle } from '@thg-commerce/gravity-theme'
import { Grid } from '@thg-commerce/gravity-system'
import { styled, spacing, css, Text } from '@thg-commerce/enterprise-theme'

const DeliveryIcon = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/Delivery'),
  { ssr: true, loading: () => <svg style={{ height: 24, width: 24 }} /> },
)
const StoreIcon = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/Store'),
  { ssr: true, loading: () => <svg style={{ height: 24, width: 24 }} /> },
)

const reusableProductBlockIconProperties = css<{ available?: boolean }>`
  fill: ${(props) =>
    props.available
      ? props.theme.colors.palette.brand.base
      : props.theme.colors.palette.greys.grey};
`

export const StyledStoreIcon = styled(StoreIcon)<{
  available: boolean
}>`
  ${reusableProductBlockIconProperties}
`

export const StyledDeliveryText = styled.p<{
  available?: boolean
  firstLine?: boolean
  firstLineTextStyle?: {
    textStyle: {
      entry: TextEntry
      style: TextStyle
    }
  }
}>`
  ${(props) =>
    props.firstLine && props.firstLineTextStyle
      ? props.available
        ? Text(
            props.firstLineTextStyle.textStyle.entry,
            props.firstLineTextStyle.textStyle.style,
          )
        : Text(props.firstLineTextStyle.textStyle.entry, 'default')
      : Text('small', 'default')};
  color: ${(props) =>
    props.available
      ? props.theme.colors.palette.greys.darker
      : props.theme.colors.palette.greys.grey};
`

export const StyledDeliveryIcon = styled(DeliveryIcon)<{
  available?: boolean
}>`
  path {
    ${reusableProductBlockIconProperties}
  }
`

export const StyledGrid = styled(Grid)`
  column-gap: ${spacing(2)};
  align-items: center;
  margin-bottom: ${spacing(2)};
  grid-template-columns: min-content auto;
`
